<template>
  <div
    class="scoring-profile-card"
    :class="[{'scoring-profile-card__loading': loading}]"
    @click.stop="$emit('select', id)"
  >
    <div class="bold-600 mb-4 flex align-center space-between">
      <div
        v-if="!isEdit"
        class="capitalize"
      >
        {{ profileName }}
      </div>
      <GlInput
        v-else
        v-model="localName"
        is-light
      />
      <div
        v-if="id"
        class="cases-context-menu-activator flex align-self-start"
        @click.stop="openContextMenu"
      >
        <gl-icon
          class="cases-context-menu__icon"
          :height="24"
          name="more"
          :width="24"
        />
      </div>
    </div>
    <div class="flex">
      <div
        class="scoring-profile-card__status mr-4"
        :class="[{'scoring-profile-card__status-active': activeStatus}]"
      >
        {{ activeStatus ? 'Active' : 'Inactive' }}
      </div>
      <div>
        <o-switch
          v-model="active"
          :disabled="active"
          @input="$emit('update', id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon.vue";
import GlInput from "@/components/gl-input.vue";
export default {
  components: {
    GlIcon,
    GlInput,
  },
  props: {
    profileName: {
      type: String,
      default: 'Default Profile'
    },
    activeStatus: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      require: true,
      default: null
    },
  },
  data() {
    return {
      active: false,
      localName: '',
      contextMenuPosition: { x: 0, y: 0 },
    }
  },
  watch: {
    activeStatus: {
      handler(to) {
        this.active = to
      },
      immediate: true
    },
    localName: {
      handler(to) {
        this.$emit('rename', to)
      },
      immediate: true
    }
  },
  methods: {
    openContextMenu(e) {
      const { x, y } = e
      this.contextMenuPosition = { x, y }
      this.$emit('ctx-open', { position: this.contextMenuPosition, id: this.id })
    }
  },
}
</script>

<style>
.scoring-profile-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--pale-grey);
  padding: 16px;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  border: 1px solid transparent;
}

.scoring-profile-card:hover {
  border: 1px solid var(--dark-grey-6-e);
}

.scoring-profile-card__status {
  background-color: var(--pale-grey);
  padding: 4px 16px;
  border-radius: 3px;
  width: fit-content;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--white);
  background: var(--dark-grey-d-3);
  min-width: 100px;
  text-align: center;
}

.scoring-profile-card__status-active {
  background: var(--reflex-bluet);
}

.scoring-profile-card__selected {
  border: 1px solid var(--reflex-bluet);
}

.scoring-profile-card__loading {
  opacity: 0.5;
}
</style>
