<template>
  <div class="fullwidth tags-scoring pa-3">
    <div class="score-clock-title">
      Current Tags Score
    </div>
    <div class="flex m-display-block align-flex-end space-between mb-4 mt-2">
      <gl-input
        v-model="search"
        class="mr-4 fullwidth"
        clearable
        data-cy="tagName"
        :height="40"
        label="TAG NAME"
        name="tag"
        placeholder="Search by a tag name"
        @clear="resetSearch"
      />
      <button
        class="gl-button gl-button--dark gl-button--padder action-btn m-fullwidth m-mt-3"
        data-cy="addTypeSearch"
        @click="loadData(true)"
      >
        search
      </button>
    </div>
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
      <o-table
        backend-sorting
        class="main-table-wrap"
        :class="{ 'o-table-has-pagination': tagData.length && totalPages > 1 }"
        :data="tagData"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @sort="onSort"
      >
        <o-table-column
          v-slot="props"
          field="name"
          label="Tag name"
          sortable
        >
          {{ props.row.name }}
        </o-table-column>
        <o-table-column
          v-if="withDefScore"
          v-slot="props"
          field="defScore"
          label="Default score"
          sortable
        >
          <div v-if="props.row.defScore === undefined">
            <GlScoreLevelChip
              v-if="!loading"
              :level="props.row.score ? props.row.score.toFixed(0) : 0"
              score
            />
          </div>

          <GlScoreLevelChip
            v-else-if="!loading"
            :level="props.row.defScore ? props.row.defScore.toFixed(0) : 0"
            score
          />
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="score"
          label="Assigned Score"
          sortable
          :visible="canEditScore && canResetScore"
        >
          <div v-if="props.row.defScore === undefined">
            --
          </div>
          <GlScoreLevelChip
            v-else-if="!loading"
            :level="props.row.score ? props.row.score.toFixed(0) : 0"
            score
          />
        </o-table-column>
        <o-table-column
          v-slot="props"
          align="right"
          field="actions"
          label="Actions"
          :visible="canEditScore && canResetScore"
        >
          <div class="flex">
            <div style="min-width: 30px">
              <gl-icon
                v-tooltip.top="'Edit Tag'"
                class="pointer"
                :height="24"
                name="edit-action"
                :width="24"
                @click="editTag(props.row.name, props.row.score, props.row.metadata, props.row._id)"
              />
            </div>
            <gl-icon
              v-tooltip.top="'Reset Score'"
              class="pointer mr-2"
              :height="24"
              name="reload"
              :width="24"
              @click="resetScoreHandler(props.row)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-1 m-pa-0 m-pt-3">
        <div class="flex m-display-block align-center pa-3 m-pa-0">
          <div class="mr-2 m-mr-0 m-mb-2 fs-14 bold">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="tagData.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <EditTagModal
      v-if="showEditModal"
      :id="editTagId"
      v-model="showEditModal"
      :loading="loading"
      :metadata="editMeta"
      :score="editTagScore"
      :tag="editTagName"
      @close="showEditModal = false"
      @edit="updateTag"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="showConfirmModal = false"
      @confirm="confirm"
    />
    <resetScoreModal
      v-if="showResetModal"
      v-model="showResetModal"
      :name="resetData.name"
      @close="showResetModal = false"
      @confirm="resetConfirm"
    />
  </div>
</template>

<script>
//Vuex
import { mapActions, mapState } from "vuex";
// Components
import vSelect from 'vue-select';
import GlIcon from '@/components/gl-icon';
import GlLoader from '@/components/gl-loader';
import EditTagModal from '../modals/EditTagModal';
import GlMenuItem from '@/components/gl-menu-item';
import GlScoreLevelChip from '@/components/gl-score-level-chip';
import resetScoreModal from "@/pages/scoring-profiles/modals/resetScoreModal.vue";
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";
// Utils
import { formatterRecalcApproveMessage } from "@/utils/text-formatter";
import GlInput from "@/components/gl-input.vue";

export default {
  name: 'TagsScoring',
  components: {
    GlInput,
    GlIcon,
    vSelect,
    GlLoader,
    GlMenuItem,
    EditTagModal,
    resetScoreModal,
    GlScoreLevelChip,
    confirmUpdateCalcModal,
  },
  props: {
    profileId: {
      type: [Number, String],
      default: null
    },
    withDefScore: {
      type: Boolean,
      default: false
    },
    canEditScore: {
      type: Boolean,
      default: true
    },
    canResetScore: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      resetData: null,
      showResetModal: false,
      loading: false,
      showConfirmModal: false,
      skipCheck: false,
      message: '',
      newTag: '',
      newScore: 0,
      newMeta: 0,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      name: '',
      metadata: '',
      search: '',
      score: 0,
      tagData: [],
      checkedRows: [],
      removedTags: [],
      editTagName: '',
      editMeta: '',
      editTagScore: '0',
      editTagId: null,
      showEditModal: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType']),
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
      immediate: true
    },
    profileId: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },
  created() {
    this.perPage = localStorage.getItem('tags-scoring-per-page') || this.perPage
    localStorage.setItem('tags-scoring-per-page', this.perPage)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatterRecalcApproveMessage,
    ...mapActions({
      getTags: 'tagging/getTags',
      editTagData: 'tagging/editTag',
      resetScoreTag: 'types/resetScoreTag'
    }),
    resetScoreHandler(data) {
      this.showResetModal = true
      this.resetData = data
    },
    resetConfirm(name) {
      this.resetScoreTag({ name, profileId: this.profileId, id: this.resetData._id }).then((success, data) => {
        if (success) {
          this.showResetModal = false
          this.loadData()
        } else {
          this.$toasted.global.error({ message: `${data.message}`})
        }
      })
    },
    confirm() {
      this.showEditModal = false
      this.skipCheck = true
      if (this.newMeta || this.newScore || this.newTag) {
        this.updateTag(this.newTag, this.newScore, this.newMeta)
      }

      if (this.removedTags.length > 0) {
        this.removeTag(this.removedTags)
      }
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
    editTag(name, score, metadata, id) {
      this.editTagName = name
      this.editTagScore = score
      this.editTagId = id
      this.editMeta = metadata
      this.showEditModal = true
    },
    updateTag(name, score, metadata, id) {
      this.message = ''
      this.newTag = ''
      this.newScore = 0
      this.newMeta = 0
      this.loading = true

      this.editTagData({ name: this.editTagName, newName: name, score: Number(score), metadata, skipCheck: this.skipCheck, profileId: this.profileId, isLabeling: true, id }).then(({ success, data }) => {
        if (success) {
          this.editTagName = ''
          this.editTagScore = ''
          this.editTagId = null
          this.showEditModal = false
          this.showConfirmModal = false
          this.skipCheck = false
          this.loadData()
        } else {
          this.newTag = name
          this.newScore = Number(score)
          this.newMeta = metadata
          if (data.showPopup || data?.response?.messageData) {
            this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
            this.showConfirmModal = true
          } else this.$toasted.global.error({ message: `${data.message}`})
        }
      }).finally(() => {
        this.loading = false
      })
    },
    resetSearch() {
      this.search = ''
      this.loadData()
    },
    searching() {
      this.currentPage = 1
      this.loadData()
    },
    async loadData() {
      this.loading = true
      this.$emit('loading', this.loading)
      const sendParams = this.formattedSendData()
      const { data: { total, tagData } } = await this.getTags(sendParams)
      this.tagData = tagData
      this.total = total
      this.totalPages = Math.ceil(this.total / this.perPage)
      this.loading = false
      this.$emit('loading', this.loading)
      this.checkedRows = []
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field
      this.loadData()
    },
    formattedSendData() {
      this.perPage = localStorage.getItem('tags-scoring-per-page') || this.perPage

      return {
        sortField: this.sortField || undefined,
        count: this.perPage,
        search: this.search,
        profileId: this.profileId,
        skip: (this.currentPage - 1) * this.perPage,
        isLabeling: true,
        sortOrder: this.sortOrder || undefined
      }
    },
    countChange() {
      this.checkedRows = []
      localStorage.setItem('tags-scoring-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.checkedRows = []
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>
.tags-scoring {
  height: fit-content;
  background: #fff;
}

.score-clock-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.stat-select .vs__dropdown-toggle {
  height: 40px !important;
  background: #fff;
  border: none;
}

.stat-select__pagination{
  width: 150px;
}

.stat-select__pagination .vs__dropdown-toggle{
  background: #fff;
  border: 1px solid #f5f5f5 !important;
  height: 35px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.action-btn {
  height: 40px;
  width: 165px;
}

.tags-scoring {
  border-radius: 3px;
}
.tags-scoring .o-table__wrapper {
  overflow: auto;
}
</style>
